:root {
  --four-square-box-size: 300px;
  --four-square-image-size: 150px;
}

.home-banner-container {
  width: 100vw;
  overflow-x: hidden;
}

.home-banner {
  width: 100vw;
}

.home-about-container {
  font-size: 1.5rem;
  margin-left: var(--side-margin);
  margin-right: var(--side-margin);
}

.home-about-container > h2 {
  text-align: center;
}

.home-side-by-side {
  display: grid;
  grid-template-columns: 2fr 1fr;
  grid-template-rows: auto;
  column-gap: 80px;
  row-gap: 40px;
  align-items: center;
}

.home-side-by-side.store-section {
  grid-template-columns: 1fr 2fr 1fr;
}

.home-side-by-side img {
  width: 100%;
  border-radius: 30px;
}

.home-four-square {
  display: grid;
  place-content: center;
}

.four-square-box {
  display: grid;
  height: var(--four-square-box-size);
  width: var(--four-square-box-size);
  grid-template-columns: var(--four-square-image-size) var(--four-square-image-size);
  grid-template-rows: var(--four-square-image-size) var(--four-square-image-size);
  border-radius: 4px;
  overflow: hidden;
}

.four-square-box > img {
  height: var(--four-square-image-size);
  width: var(--four-square-image-size);
}

.text {
  width: 100%;
  text-align: left;
  font-family: 'Open Sans', 'Arial Narrow', Arial, sans-serif;
  font-size: 30px;
}

.tail {
  text-align: center;
  margin-top: 40px;
  margin-bottom: 40px;
}

.tail img {
  height: 60px;
}

.tail-2 {
  margin-top: 40px;
}

.title {
  font-family: 'Oswald';
  text-align: center;
  color: rgb(83, 204, 143);
  font-size: 60px;
  margin-top: 0px;
  margin-bottom: 0px;
}

@media screen and (max-width: 1000px) {
  .home-side-by-side {
    margin-left: 20px;
    margin-right: 20px;
    grid-template-columns: 1fr;
    grid-template-rows: auto auto;
  }

  .home-side-by-side.store-section {
    margin-left: 20px;
    margin-right: 20px;
    margin-top: 20px;
    grid-template-columns: 1fr;
    grid-template-rows: auto auto;
  }

  .text {
    font-size: 15px;
  }

  .title {
    font-size: 30px;
  }
}

@media screen and (max-width: 540px) {
  .home-about-container {
    font-size: 0.9rem;
  }
}
