.navbar-container {
  display: flex;
  margin: 20px;
  align-items: center;
}

.navbar-emblem {
  font-size: 40px;
  font-family: 'Comic Sans';
  flex: 1;
  display: flex;
}

.navbar-emblem a {
  display: flex;
  align-items: center;
  align-content: center;
}

.navbar-emblem img {
  height: 60px;
  width: auto;
}

.navbar-items {
  font-family: 'Open Sans';
  display: flex;
  column-gap: 10px;
  align-items: center;
}

.art-link {
  font-family: cursive;
  font-size: 21px;
}

@media screen and (max-width: 1000px) {
  .navbar-emblem img {
    height: 40px;
  }

  .art-link {
    font-size: 17px;
  }
}

@media screen and (max-width: 350px) {
  .art-link {
    font-size: 12px;
  }
}
