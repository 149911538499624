.home-welcome {
  font-family: 'Open Sans';
  /* margin-left: var(--side-margin);
    margin-right: var(--side-margin); */
  text-align: left;
}

.home-welcome-list {
  font-family: 'Open Sans';
  margin-top: 20px;
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: 1fr;
}

.home-welcome-list-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  font-family: 'Open Sans', 'Arial Narrow', Arial, sans-serif;
  font-size: 20px;
}

.home-welcome-list-item img {
  width: 150px;
  border-radius: 10px;
}

@media screen and (max-width: 1000px) {
  .home-welcome-list {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
  }

  .home-welcome-list-item {
    flex-direction: row;
    text-align: left;
    column-gap: 20px;
    font-size: 15px;
  }

  .home-welcome-list-item img {
    width: 100px;
  }
}

@media screen and (max-width: 700px) {
  .home-welcome-list {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
  }

  .home-welcome-list-item img {
    width: 70px;
  }
}

@media screen and (max-width: 450px) {
  .home-welcome-list-item img {
    width: 50px;
  }
}

h2 {
  text-align: center;
  font-size: 40px;
}
