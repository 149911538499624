.mint-overall-container {
  margin: auto;
  text-align: center;
  font-size: 25px;
}

.mint-title {
  color: rgb(83, 204, 143);
  font-size: 50px;
  margin-bottom: 10px;
}

.mint-counter {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
  margin-bottom: 10px;
}

.mint-amount-number {
  display: inline-block;
}

.mint-accent {
  color: var(--accent-color);
}

.mint-button-container {
  margin-top: 20px;
  padding: 20px;
  font-size: 30px;
}

.mint-disabled {
  cursor: not-allowed;
  color: var(--accent-color);
  border-color: var(--accent-color);
}

.mint-disabled:hover {
  box-shadow: none;
}

.mint-error {
  color: var(--accent-color);
}

.mint-error,
.mint-success {
  font-weight: bold;
}

.mint-counter-button:last-child {
  margin-left: 10px;
}

.mint-disconnect {
  margin: 25px;
}

.mint-price {
  font-size: 50px;
  color: rgb(83, 204, 143);
}
