.gold-title {
  color: rgb(255, 215, 0);
}
.gold-card img {
  width: 25%;
  border-radius: 15px;
}

.gold-card-regular {
  display: grid;
  grid-template-columns: 1fr 2fr 1fr;
  column-gap: 20px;
  align-items: center;
}

.gold-card-regular img {
  width: 100%;
}

.gold-card-images-mobile {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 20px;
  border-radius: 15px;
}

.gold-card-images-mobile img {
  width: 100%;
}
